<template>
  <div>
    <div class="formBox">
      <a-form :form="form" @submit="handleSearch">
        <a-row :gutter="24">
          <a-col :span="8">
           <div style="display: flex; align-item: center;">
              <a-form-item>
              <a-select style="width: 120px" placeholder="请选择">
                <a-select-option value="jack"> Jack </a-select-option>
                <a-select-option value="lucy"> Lucy </a-select-option>
                <a-select-option value="Yiminghe"> yiminghe </a-select-option>
              </a-select>
            </a-form-item>
            <p style="height: 40px; line-height: 40px;margin-left: 10px;">2020年10月1号 - 2020年10月2号</p>
           </div>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商家分类">
              <a-select style="width: 220px" placeholder="请选择商家分类">
                <a-select-option value="jack"> Jack </a-select-option>
                <a-select-option value="lucy"> Lucy </a-select-option>
                <a-select-option value="Yiminghe"> yiminghe </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商家标签">
              <a-select style="width: 220px" placeholder="请选择商家标签">
                <a-select-option value="jack"> Jack </a-select-option>
                <a-select-option value="lucy"> Lucy </a-select-option>
                <a-select-option value="Yiminghe"> yiminghe </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商品品牌">
              <a-select style="width: 220px" placeholder="请选择品牌">
                <a-select-option value="jack"> Jack </a-select-option>
                <a-select-option value="lucy"> Lucy </a-select-option>
                <a-select-option value="Yiminghe"> yiminghe </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
           <a-col :span="7">
            <a-button type="primary" html-type="submit" style="margin-right: 10px;"> 搜索 </a-button>
            <a-button type="primary"> 数据导出 </a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      bordered
    >

    <div slot="info" slot-scope="info" class="product__desc">
      <img src="" alt="">
      <div>
        <p>{{info.name}}</p>
        <p>100</p>
      </div>
    </div>

    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "商品信息",
    dataIndex: "productInfo",
    key: "productInfo",
    scopedSlots: { customRender: 'info' },
  },
  {
    title: "访客数",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "浏览量",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "加购人数",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "付款人数",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "新成交客户数",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "新成交客户数占比",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "老成交客户数",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "老成交客户数占比",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "商品转化率",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "付款商品件数",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "销售额",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "推荐人数",
    dataIndex: "address",
    key: "address",
  },
];

const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "1",
    productInfo: {
      name: '商品名称'
    }
  },
];
export default {
  name: "EffectPane",
  data() {
    return {
      data,
      columns,
      pagination: {
        total: 100,
        showTotal: (total, range) => {
          console.log(total, range);
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
      },
      form: this.$form.createForm(this, { name: "advanced_search" }),
    };
  },
  methods: {
    handleSearch(e) {
      e.preventDefault();
      this.form.validateFields((error, values) => {
        console.log("error", error);
        console.log("Received values of form: ", values);
      });
    },

    handleReset() {
      this.form.resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.product__desc {
  display: flex;
  align-items: center;
  > img {
    width: 80px;
    height: 80px;
    display: inline-block;
    margin-right: 4px;
  }
  p {
    &:last-child {
      color: red;
    }
  }
}
</style>